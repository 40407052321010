import React from 'react'
import './App.css'
import Pdf from  './img/1.pdf' 

function metodSopr() {
  return (
    <div className='metodSopr'><p>Пояснительная записка</p>
<p>Электронный тренажер предназначен для углубления и закрепления 
полученных знаний по учебному предмету Специальная технология и 
производственному обучению по специальности «Монтаж и эксплуатация 
охранно-пожарной сигнализации».</p>
<p>Учащиеся, получив в качестве задания план помещения и набор 
технических средств пожарной сигнализации, принимают решения о 
необходимом количестве приборов, местах их установки и подключении к 
линиям связи и питания. За правильностью деятельности учащихся мастер 
следит по оценке тренажёра. </p>
<p>Электронный тренажер призван помочь преподавателю и мастеру 
производственного обучения в организации занятий учащихся, контроля 
уровня освоения материала и практическим навыкам.</p>
<p>Проведение уроков производственного обучения на электронном 
тренажере способствует повышению эффективности восприятия 
информации, повышению познавательной деятельности учащихся, 
формированию профессиональных умений и навыков за счет многократного 
повторения отрабатываемых действий.</p>
<iframe src={Pdf} style={{width:'100%', height:'100%', alignItems:'center'}}/>

</div>


  )
}

export default metodSopr